// import logo from '../../../img/logo.png';
import { Link } from 'react-router-dom';
import eagles_logo from '../../../assets/img/logos/eagles_logo_removebg.png';
import { UilFacebookF, UilTwitterAlt, UilYoutube, UilFacebook, UilWhatsapp, UilTelegram } from '@iconscout/react-unicons';

const Footer = () => {
    // const instructorLoginStatus = localStorage.getItem('instructorLoginStatus')

    return (
    <>
        {/* { instructorLoginStatus != 'true' &&  */}

        <footer className="footer">
            <div className="footer__container grid container">
                <div className="footer__content">
                    <h3 className="footer__title">Eagles of Zion</h3>
                    <p className="footer__description"><span>Soaring for Glory!</span></p>
                    <div className="footer__social">
                        <div className="course__social">
                            <a href="https://www.facebook.com/EAGLESOFZION" className="course__social-link"><UilFacebook /></a>
                            <a href="https://chat.whatsapp.com/FjJpWmTAPYK7gMTTvgzjzp" className="course__social-link"><UilWhatsapp /></a>
                            <a href="https://t.me/nyfmzgcmeetingroom/1" className="course__social-link"><UilTelegram /></a>
                        </div>
                    </div>
                </div>

                <div className="footer__content">
                    <h3 className="footer__title">Register/Login</h3>
                    <ul className="footer__links">
                        <li><Link to="/conference-registration" className="footer__link">Conference Registration</Link></li>
                        <li><Link to="/registration" className="footer__link">Membership Registration</Link></li>
                        <li><Link to="/login" className="footer__link">Member Login</Link></li>
                        <li><Link to="/" className="footer__link">Frequent Questions</Link></li>
                        
                    </ul><br /><br />
                </div>

                <div className="footer__content">
                    <h3 className="footer__title">Information</h3>
                    <ul className="footer__links">
                        <li><Link to="#ChurchPresident_Welcome" className="footer__link">Church President Message</Link></li>
                        <li><Link to="#EaglesPresident_Welcome" className="footer__link">Eagles President Message</Link></li>
                        <li><Link to="#EaglesExcos" className="footer__link">Eagles National Executives</Link></li>
                        <li><Link to="#/CardinalActivities" className="footer__link">Our Cardinal Events</Link></li>
                    </ul>
                </div>

                <div className="footer__content">
                    <h3 className="footer__title">Pages</h3>
                    <ul className="footer__links">
                        <li><Link to="/" className="footer__link">Home</Link></li>
                        <li><Link to="/about" className="footer__link">About</Link></li>
                        <li><Link to="/zone" className="footer__link">Zones</Link></li>
                        <li><Link to="/contact" className="footer__link">Contact</Link></li>
                    </ul>
                </div>

            </div>

            <span className="footer__copy">
                &copy; Eagles of Zion. Web Team.
            </span>
        </footer>
    </>
  )
}

export default Footer


        {/* <div className="footer-column">
          <h4>Contact</h4>
          <p><u>Email</u>: <br />
          nationalyouthfellowshipmzgc<br />@gmail.com</p><br />

          <p>Phone: <br />
          +234 803-854-7692</p>
        </div> */}

