import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';
import { UilArrowRight, UilFacebook, UilWhatsapp, UilTelegram } from '@iconscout/react-unicons';

const EaglesPresident_Welcome = () => {
  const slug = 'eagles-president-welcome';
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${baseUrl}/cms/${slug}/`)
      .then(response => {
        console.log(response.data);
        setData({
          ...response.data
        });
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, [slug]);

  // If data is null or title is missing, don't render the section
  if (!data || !data.title) {
    return null; // Return nothing, so nothing is displayed until data is fetched
  }

  return (
    <>
    <section className="welcome_eagles grid grey-bg" id="EaglesPresident_Welcome">
        <div className="about__data pl-2">
          <h2 className="section__title about__title">{data.title}</h2>
          <h1 className="office subtitle">{data.subtitle}</h1><br />
          
          <p className="about__description">
          {data.content}</p>
          {/* the earth - that whosoever believes in HIM shall not perish</p> */}

          <br />
          <span className="brand__button quicklinks">
            Read the Eagles' President Welcome Message <UilArrowRight className="brand__button-icon" />
          </span>

          <br /><br />
          <div className="course__social">
              <a href="https://www.facebook.com/EAGLESOFZION" className="course__social-link"><UilFacebook /></a>
              <a href="https://chat.whatsapp.com/FjJpWmTAPYK7gMTTvgzjzp" className="course__social-link"><UilWhatsapp /></a>
              <a href="https://t.me/nyfmzgcmeetingroom/1" className="course__social-link"><UilTelegram /></a>
          </div>
        </div>

        
        <div className="welcome1_left full-image" id="mzgc">
          <div className="about__thumb">
            <img src={data.image} className="about__thumb-img" alt="" />

            <div>
                <h1 className="office">{data.person_name}</h1>
            </div>
            <div>
                <div className="office_title">
                  {data.person_office}
                </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default EaglesPresident_Welcome
