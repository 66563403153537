import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';

import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';

const EaglesExcos = () => {
  const slug = 'eagles-national-excos';
  const [data, setData] = useState([]);
  const [excos, setExcos] = useState([]);

  useEffect(() => {
    axios.get(`${baseUrl}/cms/${slug}/`)
      .then(response => {
        console.log(response.data);
        setData({
          ...response.data
        });
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, [slug]);

  useEffect(() => {
    axios.get(`${baseUrl}/national-excos/`)
      .then(response => {
        console.log(response.data);
        setExcos(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  // If data is null or title is missing, don't render the section
  if (!data || !data.title) {
    return null; // Return nothing, so nothing is displayed until data is fetched
  }

  return (
    <>
     <section className="section grey-bg" id="EaglesExcos">
     <h2 className="section__title about__title">{data.title}</h2>
     <h1 className="office text-center subtitle">{data.subtitle}</h1><br />
        <Swiper 
          spaceBetween={22}
          breakpoints={{
            1206: {
                slidesPerView: 4, /* 5. NORMAL LAPTOPS (E.G MY OWN) TO LARGE @media screen and (min-width: 1207px)*/
            },
            1024: {
              slidesPerView: 3.5, /* 4. SMALL LAPTOP SCREENS @media screen and (min-width: 1025px) and (max-width: 1206px) */
            },
            767: {
                slidesPerView: 2.5, /* 3. MEDIUM SCREENS @media screen and (min-width: 768px) and (max-width: 1024px) */
            },
            576: {
            slidesPerView: 2, /* 2. TABLETS @media screen and (min-width: 577px) and (max-width: 767px) */
            },
            350: {
                slidesPerView: 1.3, /* 1. SMALL/MOBILE @media screen and (min-width: 351px) and (max-width: 576px) */
            },
            // when window width is >= 0px (default for all sizes)
            0: {
              slidesPerView: 1.3,
            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          >

          <div className="teachers__container grid container">

          {excos.map((dt, index) => (

          <div key={index}>   
            <SwiperSlide>
            <div className="about__container grid container reverse full-image reverse-ex">
                <div className="about__group item">
                    <div className="about__thumb">
                        <img src={dt.image} className="about__thumb-img excos" alt="" />
                        <div><h2 className="exconame">{dt.title} {dt.fname} {dt.sname}</h2></div>
                        <div>
                            <div className="office_title">
                              {dt.nationalexco_position}
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </SwiperSlide>
          </div>
          ))}

          </div>
        </Swiper>
      </section> 
    </>
  )
}

export default EaglesExcos
