import { useState, useEffect } from 'react';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Activities = () => {
  const [activities, setActivities] = useState([]);
  const [activityGallery, setActivityGallery] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch activity categories and randomly select a category on first load
  useEffect(() => {
    axios.get(`${baseUrl}/activity/`)
      .then((response) => {
        setActivities(response.data);
        
        // Select a random category on first load
        const randomActivity = response.data[Math.floor(Math.random() * response.data.length)];
        setSelectedCategory(randomActivity.id);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setActivities([]);
        setLoading(false);
      });
  }, []);

  // Fetch activity gallery data
  useEffect(() => {
    axios.get(`${baseUrl}/activity-gallery/`)
      .then((response) => {
        setActivityGallery(response.data);
      })
      .catch(error => {
        console.error(error);
        setActivityGallery([]);
      });
  }, []);

  // Filter gallery based on selected category
  const filteredGallery = selectedCategory
    ? activityGallery.filter(gallery => gallery.activity === selectedCategory)
    : [];

  return (
    <div className="activity-container" id="CardinalActivities"><br />
      <h2 className="section__title">Cardinal Activities</h2>
      <h1 className="office subtitle">Choose an event to browse its gallery</h1><br />

      {/* Loading Spinner */}
      {loading && <div className="spinner"></div>}

      {/* Display activity cards */}
      {!loading && (
        <div className="activity-buttons">
          {activities.map(activity => (
            <div
              key={activity.id}
              className={`button button__header ${selectedCategory === activity.id ? 'active' : ''}`}
              onClick={() => setSelectedCategory(activity.id)}
            >
              {activity.name}
              {/* <p>{activity.description}</p> */}
            </div>
          ))}
        </div>
      )}

      {/* Display selected category */}
      <h2>
        Selected Category: {selectedCategory ? activities.find(act => act.id === selectedCategory)?.name : 'None'}
      </h2>

      <Swiper 
          spaceBetween={22}
          breakpoints={{
            1206: {
                slidesPerView: 2.5, /* 5. NORMAL LAPTOPS (E.G MY OWN) TO LARGE @media screen and (min-width: 1207px)*/
            },
            1024: {
              slidesPerView: 2.5, /* 4. SMALL LAPTOP SCREENS @media screen and (min-width: 1025px) and (max-width: 1206px) */
            },
            767: {
                slidesPerView: 2, /* 3. MEDIUM SCREENS @media screen and (min-width: 768px) and (max-width: 1024px) */
            },
            576: {
            slidesPerView: 2, /* 2. TABLETS @media screen and (min-width: 577px) and (max-width: 767px) */
            },
            350: {
                slidesPerView: 1.3, /* 1. SMALL/MOBILE @media screen and (min-width: 351px) and (max-width: 576px) */
            },
            // when window width is >= 0px (default for all sizes)
            0: {
              slidesPerView: 1.3,
            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          >
            
      {/* Display filtered gallery */}
      <div className="teachers__container grid container">
        {filteredGallery.length > 0 ? (
          filteredGallery.map(gallery => (
            <div key={gallery.id}>
              
              <SwiperSlide>
                <div className="about__container grid container">
                  <div className="about__group item">
                      <div className="about__thumb">
                          <img src={gallery.image} className="about__thumb-img excos full-image" 
                            style={{width:'100%', height:'340px'}} alt="" />
                          <h2 className="exconame">{gallery.name}</h2>
                      </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          ))
        ) : (
          selectedCategory && <p>No gallery available for this event.</p>
        )}
      </div>
      </Swiper>
      
      <br />
    </div>
  );
};

export default Activities;
