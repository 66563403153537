import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';

import MZGC_President from '../../../assets/img/_Pics_Eagles_and_MZGC/MZGC_President.jpeg';
import { UilArrowRight } from '@iconscout/react-unicons';

const ChurchPresident_Welcome = () => {
  const slug = 'mzgc-president-welcome';
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${baseUrl}/cms/${slug}/`)
      .then(response => {
        console.log(response.data);
        setData({
          ...response.data
        });
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, [slug]);

  // If data is null or title is missing, don't render the section
  if (!data || !data.title) {
    return null; // Return nothing, so nothing is displayed until data is fetched
  }

  return (
    <>
    <section className="welcome_mzgc grid" id="ChurchPresident_Welcome">
        <div className="welcome1_left" id="mzgc">
          <div className="about__thumb">
            <img src={data.image} className="about__thumb-img full-image" alt="" />

            <div>
                <h1 className="office">His Grace, <br />{data.person_name}</h1>
            </div>
            <div>
                <div className="office_title">
                  {data.person_office}
                </div>
            </div>
          </div>
        </div>
        <div className="about__data pr-2">
          <h2 className="section__title about__title">{data.title}</h2>
          <h1 className="office subtitle">{data.subtitle}</h1><br />
          
          <p className="about__description">
          {data.content}
          ... Read more</p>
          
          <br />
          <span className="brand__button quicklinks">
            Read the full Welcome Address here <UilArrowRight className="brand__button-icon" />
          </span>

          <br /><br />
          <p><Link to="/about" className="button button__header">About the Church</Link></p>          
        </div>
      </section>
    </>
  )
}
export default ChurchPresident_Welcome;