import {useState, useEffect} from 'react';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
// import Header_Dashboard from '../../layouts/Header_Dashboard';
// import ContentWrapper from '../../layouts/ContentWrapper';
// import Sidebar from '../../layouts/Sidebar';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../../__public/__layouts/Header';
import Nav from '../../__public/__layouts/Nav';

const Conference_Registration = () => {
    const[data, setData] = useState({
        'fname': '',
        'sname': '',
        'title': '',
        'gender': '',
        'phone': '',
        'email': '',
        'age_range': '',
        'address': '',
        'origin': '',
        'zone': '',
        'position': '',
        'mode_of_attendance': '',
        'professional_session': '',
        'payment_method': '',
        'occupation': '',
        'how_did_you_hear': '',
        'talent': '',
        'payment_detail': '',
        'image_payment': null,
        'image_gallery': null  // Ensure this is initialized as null for the file
    });
    const [zones, setZones] = useState([]); // categories

    // change element value
    const handleChange = (e) => {
        if (e.target.name === 'image_gallery' || e.target.name === 'image_payment' ) {
            setData({
                ...data, 
                [e.target.name]: e.target.files[0]  // Use files[0] for the file input
            });
        } else {
            setData({
                ...data, 
                [e.target.name]: e.target.value
            });
        }
    };

    useEffect(() => {
        axios.get(`${baseUrl}/zone/`)
          .then((response) => {
            setZones(response.data);
          })
          .catch(error => {
            setZones([]);
          });
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
    
        // Validate the form
        if (e.target.checkValidity()) {
            const formData = new FormData();

            formData.append('fname', data.fname)
            formData.append('sname', data.sname)
            formData.append('gender', data.gender)
            formData.append('phone', data.phone)
            formData.append('email', data.email)
            formData.append('age_range', data.age_range)
            formData.append('address', data.address)
            formData.append('origin', data.origin)
            formData.append('zone', data.zone)
            formData.append('position', data.position)
            formData.append('mode_of_attendance', data.mode_of_attendance)
            formData.append('professional_session', data.professional_session)
            formData.append('payment_method', data.payment_method)
            formData.append('occupation', data.occupation)
            formData.append('how_did_you_hear', data.how_did_you_hear)
            formData.append('talent', data.talent)  
            formData.append('payment_detail', data.payment_detail)  

            // Only append the image if it's provided
            if (data.image_gallery && data.image_gallery !== 'null') {
                formData.append('image', data.image_gallery);
            }
            if (data.image_payment && data.image_payment !== 'null') {
                formData.append('image_payment', data.image_payment);
            }
            // formData.append('role', data.role)

            axios.post(`${baseUrl}/conference-registration/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'  // Required for file uploads
                }
            })            
            .then((response) => {
                setData({
                    'zone': '',
                    'fname': '',
                    'sname': '',
                    'title': '',
                    'gender': '',
                    'phone': '',
                    'email': '',
                    'age_range': '',
                    'address': '',
                    'origin': '',
                    'position': '',
                    'mode_of_attendance': '',
                    'professional_session': '',
                    'payment_method': '',
                    'occupation': '',
                    'how_did_you_hear': '',
                    'talent': '',
                    'payment_detail': '',
                    'image_payment': null,
                    'image_gallery': null,
                    // 'role': ''
                });
                console.log(response.data)
                successAlert();
            })
            .catch(error => {
                setData({
                    // 'status': 'error'
                });
                errorAlert(error);
            });
        } else {
            e.target.reportValidity();  // Triggers browser's built-in validation
        }
    }

    const loggedInStatus = localStorage.getItem('loggedInStatus')
    if (loggedInStatus == 'true'){
        window.location.href = '/dashboard';
    }

    const successAlert = (response) => {
        return(
            Swal.fire({        
                position: "center",
                icon: "success",
                title: "Your Registration for the Conference (GOTE 2024) is successful. Thank you",
                showConfirmButton: true
                // timer: 1500
          }).then(function() {            
            window.location.href = '/'
          })           
        )
    }
    const errorAlert = (error) => {
        Swal.fire({
            position: "center",
            icon: "error",
            title: "An error occurred!",
            text: error.message,
            showConfirmButton: false,
            timer: 1500
        });              
    }

    const printForm = () => {
        window.print(); // This triggers the browser's print dialog
    };

    return (
        <>
        <Header />
        <Nav />
        
        <Helmet>
            <link href="/reg/css/bootstrap.min.css" rel="stylesheet" />
            <link href="/reg/css/style.css" rel="stylesheet" />
        </Helmet>

        <div className="video wow fadeInUp bg-primary2" data-wow-delay="0.1s" style={{ height:'75vh'}}>
            <br />
            <p className="text-center small mb-2 text-white">Click a link of your choice below to register or login.</p>
            <h1 className="mb-2 text-warning text-center reglogin">
                <Link to="/conference-registration" className="reglogin_headerlinks active">Conference Registration (GOTE 2024)</Link>
            </h1>
            <h1 className="mb-2 text-center reglogin">
                <Link to="/registration" className="reglogin_headerlinks">Membership Registration</Link>
            </h1>
            <h3 className="text-white mb-1 reglogin text-center">
                <Link to="/login" className="reglogin_headerlinks">Member Login</Link>
            </h3>
        </div>

        <div className="container position-relative wow fadeInUp" data-wow-delay="0.1s" style={{ marginTop:'-6rem' }}>
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="bg-light p-5">
                        <h1 className="text-center m-0">Conference Registration</h1>
                        <p className="text-center small mb-4">Required fields are marked with Asterisk *</p>
                        
                        <form onSubmit={submitForm}>
                            <div className="row g-3 text-left">
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">First Name <span className="asterisk">*</span></h4>
                                    <input name="fname" onChange={handleChange} maxLength="30" 
                                        className="form-control border-0" placeholder="Your First Name" 
                                        style={{ height:'55px' }} required />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Surname <span className="asterisk">*</span></h4>
                                    <input name="sname" onChange={handleChange} maxLength="30" 
                                        className="form-control border-0" placeholder="Your Surname" 
                                        style={{ height:'55px' }} required />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Your Title</h4>
                                    <input name="title" onChange={handleChange} maxLength="20" 
                                        className="form-control border-0" placeholder="E.g. Rev. Pastor, Elder, Deacon(ess)" 
                                        style={{ height:'55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Gender</h4>
                                    <select name="gender" className="form-select border-0" style={{ height:'55px' }} 
                                        onChange={handleChange}>
                                        <option value="">Select</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Phone No. <span className="asterisk">*</span></h4>
                                    <input type="number" name="phone" onChange={handleChange} maxLength="16" 
                                        className="form-control border-0" placeholder="E.g. 08098765432" 
                                        style={{ height:'55px' }} required />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Email Address <span className="asterisk">*</span></h4>
                                    <input type="email" name="email" onChange={handleChange} maxLength="50" 
                                        className="form-control border-0" placeholder="E.g. myname@mail.com" 
                                        style={{ height:'55px' }} required />
                                </div>

                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Your Age Range </h4>
                                    <select name="age_range" className="form-select border-0" style={{ height:'55px' }} 
                                        onChange={handleChange}>
                                        <option selected>- Select Age Range -</option>
                                        <option value="Below 16">Younger than 16</option>
                                        <option value="16-20">16-20</option>
                                        <option value="21-30">21-30</option>
                                        <option value="31-40">31-40</option>
                                        <option value="40+">40+</option>
                                    </select>                                                    
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Residential Address (City and State)</h4>
                                    <input name="address" onChange={handleChange} maxLength="40" 
                                        className="form-control border-0" placeholder="E.g. Ikeja, Lagos" 
                                        style={{ height:'55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">State of Origin</h4>
                                    <select name="origin" className="form-select border-0" style={{ height:'55px' }} 
                                        onChange={handleChange}>
                                        <option value="">- Select Your Origin -</option>                                        
                                        <option value="Abia">Abia</option>
                                        <option value="Abuja (FCT)">Abuja (FCT)</option>
                                        <option value="Adamawa">Adamawa</option>
                                        <option value="Akwa Ibom">Akwa Ibom</option>
                                        <option value="Anambra">Anambra</option>
                                        <option value="Bauchi">Bauchi</option>
                                        <option value="Bayelsa">Bayelsa</option>
                                        <option value="Benue">Benue</option>
                                        <option value="Borno">Borno</option>
                                        <option value="Cross River">Cross River</option>
                                        <option value="Delta">Delta</option>
                                        <option value="Ebonyi">Ebonyi</option>
                                        <option value="Edo">Edo</option>
                                        <option value="Ekiti">Ekiti</option>
                                        <option value="Enugu">Enugu</option>
                                        <option value="Gombe">Gombe</option>
                                        <option value="Imo">Imo</option>
                                        <option value="Jigawa">Jigawa</option>
                                        <option value="Kaduna">Kaduna</option>
                                        <option value="Kano">Kano</option>
                                        <option value="Katsina">Katsina</option>
                                        <option value="Kebbi">Kebbi</option>
                                        <option value="Kogi">Kogi</option>
                                        <option value="Kwara">Kwara</option>
                                        <option value="Lagos">Lagos</option>
                                        <option value="Nasarawa">Nasarawa</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Ogun">Ogun</option>
                                        <option value="Ondo">Ondo</option>
                                        <option value="Osun">Osun</option>
                                        <option value="Oyo">Oyo</option>
                                        <option value="Plateau">Plateau</option>
                                        <option value="Rivers">Rivers</option>
                                        <option value="Sokoto">Sokoto</option>
                                        <option value="Taraba">Taraba</option>
                                        <option value="Yobe">Yobe</option>
                                        <option value="Zamfara">Zamfara</option>
                                    </select> 
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Your Zone <span className="asterisk">*</span></h4>                                        
                                    <select name="zone" onChange={handleChange} className="form-select border-0" style={{ height:'55px' }} 
                                        required>                                                        
                                        <option value="">- Choose Your Zone -</option>
                                        <option value="0">Non-Member</option>
                                        {zones.map(zone => (
                                            <option key={zone.id} value={zone.id}>{zone.name}</option>
                                        ))}
                                    </select> 
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Executive Position (if member)</h4>
                                    <input name="position" onChange={handleChange} maxLength="60" 
                                        className="form-control border-0" placeholder="E.g. Secretary (National), Provost (Zonal)" 
                                        style={{ height:'55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Planned Attendance Mode</h4>
                                    <select name="mode_of_attendance" className="form-select border-0" style={{ height:'55px' }} 
                                        onChange={handleChange}>
                                        <option value="">- Select Attendance Mode -</option>
                                        <option value="Physical">Physical</option>                                               
                                        <option value="Virtual">Virtual</option>
                                    </select> 
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Professional session you wish to attend</h4>
                                    <select name="professional_session" className="form-select border-0" style={{ height:'55px' }} 
                                        onChange={handleChange}>
                                        <option value="">- Select Professional Session -</option>
                                        <option value="Agriculture">Agriculture</option> 
                                        <option value="Business Startup">Business Startup</option> 
                                        <option value="Creative Sesign">Creative Design</option>  
                                        <option value="Digital Marketing">Digital Marketing</option>  
                                        <option value="Facebook Monetization">Facebook Monetization</option> 
                                        <option value="Legal">Legal</option> 
                                        <option value="Soft-Skill Enhancement">Soft-Skill Enhancement</option> 
                                    </select> 
                                </div>

                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Payment Method</h4>
                                    <select name="payment_method" className="form-select border-0" style={{ height:'55px' }} 
                                        onChange={handleChange}>
                                        <option value="">- Select Payment Method -</option>
                                        <option value="Bank Transfer">Bank Transfer</option> 
                                        <option value="Card">Card</option> 
                                        <option value="Cash">Cash</option> 
                                    </select> 
                                </div>
                                <div className="row col-12 bg-info">
                                    <h4 className="small font-weight-bold">Please Make Payment to this Account</h4>
                                    <h4 className=""><u>Acct No: 0737915907.</u> <br />
                                        <u>Bank: Access Bank. </u><br />
                                        <u>Acct Name: Ndarake, Princess Owoh</u>
                                    </h4>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Enter the details of your payment 
                                        <span className="asterisk">*</span></h4>
                                    <input name="payment_detail" onChange={handleChange} maxLength="100" 
                                        className="form-control border-0" placeholder="E.g. N900,000 from (UBA) Acct 123456 Anthony Joshua" 
                                        style={{ height:'55px' }} required />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Proof of Payment (Teller, screenshot)</h4>
                                    <input 
                                        type="file" 
                                        name="image_payment" 
                                        onChange={handleChange} 
                                        className="" 
                                        accept="image/*"
                                    />
                                </div>

                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Occupation</h4>
                                    <input name="occupation" onChange={handleChange} maxLength="30" 
                                        className="form-control border-0" placeholder="E.g. Trader" 
                                        style={{ height:'55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">How did you hear about GOTE?</h4>
                                    <select name="how_did_you_hear" className="form-select border-0" style={{ height:'55px' }} 
                                        onChange={handleChange}>
                                        <option value="">- Select how you heard -</option>
                                        <option value="Church">Church</option>
                                        <option value="Friend">Friend</option> 
                                        <option value="Social media">Social media</option>
                                        <option value="Online search">Online search</option> 
                                        <option value="Other event">Other event</option>
                                    </select> 
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Do you have any special talent?</h4>
                                    <input name="talent" onChange={handleChange} maxLength="30" 
                                        className="form-control border-0" placeholder="E.g. Singing, etc." 
                                        style={{ height:'55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Upload your photograph</h4>
                                    <input 
                                        type="file" 
                                        name="image_gallery" 
                                        onChange={handleChange} 
                                        className="" 
                                        accept="image/*"
                                    />
                                    <br /><br />
                                    <button type="button" className="btn btn-info btn-sm" onClick={printForm}>
                                        Print this Form
                                    </button>
                                </div>
                                
                                <div className="col-12">
                                    <button type="submit" className="btn btn-success w-100 py-3">
                                        Register for Conference
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Conference_Registration;